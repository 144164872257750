import { Children, cloneElement, useState } from "react"
import classNames from "classnames"
import linkCss from "../layouts/MainLayout.module.scss"
import css from "./Dropdown.module.scss"
import OutsideClickHandler from "react-outside-click-handler"
import { ReactComponent as Caret } from "../assets/angle-down-solid.svg"

export const Dropdown = (props) => {
  const { children, text } = props
  const [open, setOpen] = useState(false)

  return (
    <div className={css.dropdown}>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <button
          onClick={() => setOpen(!open)}
          className={classNames(css.button, linkCss.link, linkCss.linkMetis)}
        >
          <div className={css.textCaret}>
            <span>{text}</span>
            <Caret
              className={classNames(css.caretIcon, {
                [css.caretIconOpen]: open,
              })}
            />
          </div>
        </button>
      </OutsideClickHandler>
      <div className={classNames(css.dropdownContent, { [css.open]: open })}>
        {Children.map(children, (child) => {
          return cloneElement(child, {
            className: classNames(child.props.className, css.item),
          })
        })}
      </div>
    </div>
  )
}
