import axios from "axios"

export const contactFormSubmit = async (event) => {
  event.preventDefault()
  const formData = new FormData(event.target)
  return axios({
    method: "POST",
    url: "/.netlify/functions/triggerContactEmail",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(Object.fromEntries(formData)),
  })
}
