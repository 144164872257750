import classNames from "classnames"
import css from "./Section.module.scss"

const VALID_COLORS = ["gray", "primary"]
const isValidColor = (color) => VALID_COLORS.includes(color)

export const Section = (props) => {
  const {
    backgroundColor,
    blocks,
    noMarginBottom,
    center,
    children,
    footerCustom,
    fullHeight,
    hero,
    noMarginMobile,
    noMarginTop,
  } = props

  return (
    <section
      className={classNames(css.section, {
        [css[`background-${backgroundColor}`]]: isValidColor(backgroundColor),
        [css.bottomBreak]: !noMarginBottom,
        [css.fullHeight]: fullHeight,
        [css.noMarginMobile]: noMarginMobile,
        [css.topBreak]: !noMarginTop,
      })}
    >
      <div
        className={classNames(css.sectionInner, {
          [css.blocksTwo]: blocks === 2,
          [css.blocksThree]: blocks === 3,
          [css.blocksFour]: blocks === 4,
          [css.blocksThird]: blocks === "third",
          [css.blocksFourTwo]: blocks === "fourTwo",
          [css.center]: center,
          [css.footerCustom]: footerCustom,
          [css.hero]: hero,
        })}
      >
        {children}
      </div>
    </section>
  )
}
