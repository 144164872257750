import { Section } from "../components/Section"

export const EmploymentApplication = () => {
  return (
    <Section noMarginTop noMarginBottom noMarginMobile>
      <iframe
        id="JotFormIFrame-211856413699163"
        title="Employment Application"
        onload={() => window.parent.scrollTo(0, 0)}
        allowtransparency="true"
        allow="geolocation; microphone; camera; fullscreen"
        src="https://form.jotform.com/211856413699163"
        frameborder="0"
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          height: "8300px",
          border: "none",
        }}
      />
    </Section>
  )
}
