import { Section } from "../components/Section"
import classNames from "classnames"
import css from "./Pages.module.scss"
import Photo from "../assets/woman-helping-boy-with-down-syndrome-draw.jpg"
import { Button } from "../components/Button"
import { ReactComponent as LinkIcon } from "../assets/up-right-from-square-solid.svg"

export const Careers = () => {
  return (
    <>
      <Section noMarginTop noMarginBottom center>
        <h1 className={classNames(css.h1, css.center)}>Careers</h1>
      </Section>
      <Section blocks={2} noMarginTop>
        <div className={classNames(css.colJustifyCenter, css.aboutTextWrapper)}>
          <h2 className={css.h3}>A New Horizon Care Team</h2>
          <p>
            Joining A New Horizon's care team means more than just a job - it's
            a chance to impact lives and forge lasting connections. Every day
            presents an opportunity to create meaningful experiences with our
            members and their families. While the specific care you provide may
            vary based on individual needs, our overarching aim is to empower
            each person, regardless of their abilities, to thrive in every facet
            of life.
          </p>
          <h2 className={css.h3}>Employment Application</h2>
          <p>
            The first step in our hiring process is to{" "}
            <b>fill out our employment application form</b> by clicking the
            button link below. We will review your application and reach back
            out to you with next steps. If you have additional questions or
            would like to know the status of your application you can contact us
            at{" "}
            <a
              className={css.contactLink}
              href='mailto:"emurphy@anewhorizon.agency"'
            >
              emurphy@anewhorizon.agency
            </a>
            .
          </p>
          <p>We would love for you to work with us!</p>
          <Button link="/employment-application" square>
            Employment Application Form{" "}
            <LinkIcon className={css.linkIconInButton} />
          </Button>
        </div>
        <img
          className={css.photo}
          alt="woman helping boy with down syndrome draw"
          src={Photo}
        />
      </Section>
    </>
  )
}
