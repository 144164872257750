import css from "./Carousel.module.scss"

export const Carousel = (props) => {
  const { items } = props

  return (
    <div className={css.slider}>
      <div className={css.slideTrack}>
        {[...items, ...items, items[0]].map(({ src, alt }, index) => {
          return (
            <div key={`carousel-${index}`} className={css.slide}>
              <img src={src} alt={alt} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
