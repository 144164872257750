import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./styles/index.css"
import reportWebVitals from "./reportWebVitals"
import { MainLayout } from "./layouts/MainLayout"
import { Home } from "./pages/Home"
import { About } from "./pages/About"
import { Services } from "./pages/Services"
import { Contact } from "./pages/Contact"
import { NotFound } from "./pages/NotFound"
import { Careers } from "./pages/Careers"
import { EmploymentApplication } from "./pages/EmploymentApplication"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <MainLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route
          path="employment-application"
          element={<EmploymentApplication />}
        />
        <Route
          path="services/applied-behavior-analysis"
          element={<Services careType="applied-behavior-analysis" />}
        />
        <Route
          path="services/positive-behavior-support"
          element={<Services careType="positive-behavior-support" />}
        />
        <Route
          path="services/developmental-disability"
          element={<Services careType="developmental-disability" />}
        />
        <Route
          path="services/elderly-and-physically-disabled"
          element={<Services careType="elderly-and-physically-disabled" />}
        />
        <Route
          path="services/private-home-care"
          element={<Services careType="private-home-care" />}
        />
        <Route path="contact" element={<Contact />} />
        <Route path="careers" element={<Careers />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </MainLayout>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
