import { Section } from "../components/Section"
import { Link } from "react-router-dom"
import { ContactForm } from "../components/ContactForm"
import { Map } from "../components/Map"
import classNames from "classnames"
import css from "./Pages.module.scss"
import { Question } from "../components/Question"

export const Contact = () => {
  return (
    <>
      <Section noMarginTop noMarginBottom>
        <h1 className={classNames(css.h1, css.center)}>Contact Us</h1>
        <div className={css.contactUsDetail}>
          <p>
            Our Mission has always been to take our client's best interest to
            heart. We cater to the goals of our clients, their parents and
            guardians. We offer a wide range of services and we understand that
            each family's situation is unique.
          </p>
          <p>
            We'd love to get in touch and learn how we can best serve you.
            Please let us know how we can help!
          </p>
          <p>
            See also our{" "}
            <Link to="#FAQ" className={css.plainLink}>
              FAQ
            </Link>{" "}
            below that may help with your questions.
          </p>
        </div>
      </Section>
      <Section noMarginTop noMarginBottom center noMarginMobile>
        <ContactForm />
      </Section>
      <Section blocks={2} noMarginTop center>
        <div className={css.locationWrapper}>
          <div>
            <p className={classNames(css.contactTitle, css.center)}>
              Hours of Operation
            </p>
            <table
              className={classNames(css.table, css.contactDetail, css.center)}
            >
              <tbody>
                <tr>
                  <th>Mon - Fri</th>
                  <td>8am - 4pm</td>
                </tr>
                <tr>
                  <th>Sat - Sun</th>
                  <td>CLOSED</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p className={classNames(css.contactTitle, css.center)}>Address</p>
            <p className={classNames(css.contactDetail, css.colAlignCenter)}>
              <span>2915 E Baseline Rd</span>
              <span>Suite 108</span>
              <span>Gilbert, AZ 85234</span>
            </p>
          </div>
          <div>
            <p className={classNames(css.contactTitle, css.center)}>
              Phone / Email
            </p>
            <p className={classNames(css.contactDetail, css.colAlignCenter)}>
              <a className={css.contactLink} href="tel:480-227-9172">
                (480) 227-9172
              </a>
              <a
                className={css.contactLink}
                href='mailto:"emurphy@anewhorizon.agency"'
              >
                emurphy@anewhorizon.agency
              </a>
            </p>
          </div>
        </div>
        <div className={css.map}>
          <p className={css.center}>
            <i>*Located near the corner of Baseline and Lindsay</i>
          </p>
          <br />
          <a
            href="https://maps.app.goo.gl/nRJ4QvtGmQ1JJybv5"
            target="_blank"
            rel="noreferrer"
          >
            <Map />
          </a>
        </div>
      </Section>
      <Section noMarginTop>
        <div id="FAQ" />
        <h2 className={css.h2}>FAQs</h2>
        <Question question="Interested in becoming a care provider?">
          Check out our{" "}
          <Link to="/careers" className={css.plainLink}>
            careers
          </Link>{" "}
          page for more info.
        </Question>
        <Question question="Do you have a current waitlist?">
          Yes, wait time varies depending on location, time of service provision
          and setting type (clinic vs. home).
        </Question>
        <Question question="Can a family member be my paid caregiver?">
          Yes, family members, friends, or neighbors are often hired as the
          first choice of caregiver to provide services for their loved ones.
          Typically, family members can be hired within a week. We understand
          that everyone's homecare requirements are unique, that is why we
          assign the most qualified and compatible caregivers to meet your
          specialized needs.
        </Question>
        <Question question="What is Arizona Long Term Care System (ALTCS)?">
          Arizona Long Term Care System is the State of Arizona's Medicaid
          program that provides long term care services, at little or no cost,
          to financially and medically eligible Arizona residents who are 65
          years young, blind, or disabled. Once approved for ALTCS, Arion can
          help start the in-home services with a qualified and compassionate
          caregiver.
        </Question>
        <Question question="How do I apply for ALTCS?">
          To apply, you will need to complete an application. To start an
          application, call ALTCS toll-free at 888-621-6880. Another person can
          act on behalf of the applicant during the application process.
          Individuals will need to provide documents to show that they meet
          financial and non-financial eligibility requirements. Typically, the
          application process can take anywhere from 30-60 days.
        </Question>
        <Question question="What are the non-financial eligibility requirements?">
          To be eligible for ALTCS, you must be an AZ resident, have a Social
          Security Number, be a citizen or qualified immigrant, be determined in
          need of a nursing home level of care as determined by AHCCCS, live in
        </Question>
        <Question question="What are the financial eligibility requirements?">
          If single, the applicant's income (wages, Social Security benefits,
          pensions, veteran's benefits, annuities, SSI payments, IRAs, etc.)
          must be no higher than $2,349/month to become eligible for Medicaid.
          ALTCS will take account of your personal finances. For single
          individuals, ALTCS benefits are an option for individuals who DO NOT
          have more than $2,000 in liquid assets, such as a checking account.
          Married couples can have more assets because of the living expenses of
          the spouse. If you are married, ask for a Community Spouse Information
          Sheet.
        </Question>
        <Question question="How does ALTCS determine if I am medically eligible?">
          Once you have been determined financially eligible, a registered nurse
          or social worker will determine if you are medically eligible in a
          face-to-face interview. This medical assessment, determines whether a
          person is truly in need of long term care. The total need is
          determined by a point system; a successful application score is 60
          points or higher.
        </Question>
        {/* verify faq question */}
        {/* <Question question="What insurance companies is A New Horizon contracted with for home care services through ALTCS?">
          A New Horizon is an approved ALTCS provider, and we are contracted
          with two managed care organizations: United Health Care and Banner
          University Family Care.
        </Question> */}
      </Section>
    </>
  )
}
