import { Link } from "react-router-dom"
import css from "./Button.module.scss"
import classNames from "classnames"

export const Button = (props) => {
  const {
    children,
    float,
    link,
    naked,
    outerPadding,
    square,
    targetBlank,
    type,
  } = props

  const Element = link ? Link : "button"

  return (
    <Element
      {...(link && { to: link })}
      {...(targetBlank && { target: "_blank" })}
      {...(type && { to: type })}
      className={classNames(css.button, {
        [css.float]: float,
        [css.link]: !!link,
        [css.naked]: naked,
        [css.outerPadding]: outerPadding,
        [css.square]: square,
      })}
    >
      {outerPadding ? (
        children
      ) : (
        <div className={css.buttonInner}>{children}</div>
      )}
    </Element>
  )
}
