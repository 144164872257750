import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Logo } from "../components/Logo"
import classNames from "classnames"
import css from "./MainLayout.module.scss"
import { Button } from "../components/Button"
import { Section } from "../components/Section"
import { ReactComponent as NavSvg } from "../assets/nav.svg"
import { ReactComponent as XMarkSvg } from "../assets/xmark-solid.svg"
import { Dropdown } from "../components/Dropdown"

const YEAR = new Date().getFullYear()

export const MainLayout = (props) => {
  const { children } = props
  const { pathname, hash } = useLocation()
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (hash) {
      window.scrollTo({
        top: document.getElementById(hash.slice(1, hash.length)).offsetTop,
        behavior: "smooth",
      })
    }
  }, [pathname, hash])

  return (
    <>
      <div className={classNames(css.back, { [css.backOpen]: open })} />
      <nav className={classNames(css.navSidebar, { [css.open]: open })}>
        <div className={css.navSidebarTop}>
          <Logo onClick={toggleOpen} />
          <XMarkSvg className={css.closeSvg} onClick={toggleOpen} />
        </div>
        <div className={css.lineSidebar} />
        <Link onClick={toggleOpen} to="/home" className={css.linkSidebar}>
          Home
        </Link>
        <Link onClick={toggleOpen} to="/about" className={css.linkSidebar}>
          About Us
        </Link>
        <Link onClick={toggleOpen} to="/careers" className={css.linkSidebar}>
          Careers
        </Link>
        <Link
          onClick={toggleOpen}
          to="/employment-application"
          className={css.linkSidebar}
        >
          Employment Application
        </Link>
        <Link onClick={toggleOpen} to="/contact" className={css.linkSidebar}>
          Contact Us
        </Link>
        <div className={classNames(css.linkSidebar, css.headerSidebar)}>
          <i>Services</i>
        </div>
        <ul>
          <li>
            <Link
              onClick={toggleOpen}
              className={css.linkSidebar}
              to="/services/applied-behavior-analysis"
            >
              Applied Behavior Analysis
            </Link>
          </li>
          <li>
            <Link
              onClick={toggleOpen}
              className={css.linkSidebar}
              to="/services/positive-behavior-support"
            >
              Positive Behavior Support
            </Link>
          </li>
          <li>
            <Link
              onClick={toggleOpen}
              className={css.linkSidebar}
              to="/services/developmental-disability"
            >
              Developmentally Disabled
            </Link>
          </li>
          <li>
            <Link
              onClick={toggleOpen}
              className={css.linkSidebar}
              to="/services/elderly-and-physically-disabled"
            >
              Elderly and Physically Disabled
            </Link>
          </li>
          <li>
            <Link
              onClick={toggleOpen}
              className={css.linkSidebar}
              to="/services/private-home-care"
            >
              Private Home Care
            </Link>
          </li>
        </ul>
      </nav>
      <header className={css.header}>
        <nav className={css.navDesktop}>
          <Logo />
          <div className={css.links}>
            <Link to="/home" className={classNames(css.link, css.linkMetis)}>
              Home
            </Link>
            <Dropdown text="Our Services">
              <Link
                className={classNames(css.link, css.linkMetis)}
                to="/services/applied-behavior-analysis"
              >
                Applied Behavior Analysis
              </Link>
              <Link
                className={classNames(css.link, css.linkMetis)}
                to="/services/positive-behavior-support"
              >
                Positive Behavior Support
              </Link>
              <Link
                className={classNames(css.link, css.linkMetis)}
                to="/services/developmental-disability"
              >
                Developmentally Disabled
              </Link>
              <Link
                className={classNames(css.link, css.linkMetis)}
                to="/services/elderly-and-physically-disabled"
              >
                Elderly and Physically Disabled
              </Link>
              <Link
                className={classNames(css.link, css.linkMetis)}
                to="/services/private-home-care"
              >
                Private Home Care
              </Link>
            </Dropdown>
            <Dropdown text="More Info">
              <Link className={classNames(css.link, css.linkMetis)} to="/about">
                About Us
              </Link>
              <Link
                className={classNames(css.link, css.linkMetis)}
                to="/careers"
              >
                Careers
              </Link>
              <Link
                className={classNames(css.link, css.linkMetis)}
                to="/employment-application"
              >
                Employment Application
              </Link>
            </Dropdown>
          </div>
          <Button link="/contact" naked>
            Contact Us
          </Button>
        </nav>
        <nav className={css.navMobile}>
          <Logo />
          <NavSvg className={css.navSvg} onClick={toggleOpen} />
        </nav>
      </header>
      <main className={css.main}>{children}</main>
      <footer>
        <Section
          backgroundColor="primary"
          blocks={4}
          noMarginBottom
          footerCustom
        >
          <div className={css.footerLinks}>
            <div className={css.linkHeader}>LINKS</div>
            <div className={css.line} />
            <ul>
              <li>
                <Link className={css.linkFooter} to="/home">
                  Home
                </Link>
              </li>
              <li>
                <Link className={css.linkFooter} to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link className={css.linkFooter} to="/careers">
                  Careers
                </Link>
              </li>
              <li>
                <Link className={css.linkFooter} to="/employment-application">
                  Employment Application
                </Link>
              </li>
              <li>
                <Link className={css.linkFooter} to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className={css.footerLinks}>
            <div className={css.linkHeader}>SERVICES</div>
            <div className={css.line} />
            <ul>
              <li>
                <Link
                  className={css.linkFooter}
                  to="/services/applied-behavior-analysis"
                >
                  Applied Behavior Analysis
                </Link>
              </li>
              <li>
                <Link
                  className={css.linkFooter}
                  to="/services/positive-behavior-support"
                >
                  Positive Behavior Support
                </Link>
              </li>
              <li>
                <Link
                  className={css.linkFooter}
                  to="/services/developmental-disability"
                >
                  Developmentally Disabled
                </Link>
              </li>
              <li>
                <Link
                  className={css.linkFooter}
                  to="/services/elderly-and-physically-disabled"
                >
                  Elderly and Physically Disabled
                </Link>
              </li>
              <li>
                <Link
                  className={css.linkFooter}
                  to="/services/private-home-care"
                >
                  Private Home Care
                </Link>
              </li>
            </ul>
          </div>
          <div className={css.footerLinks}>
            <div className={css.linkHeader}>OFFICE</div>
            <div className={css.line} />
            <div className={css.address}>
              <span>2915 E Baseline Rd</span>
              <span>Suite 108</span>
              <span>Gilbert, AZ 85234</span>
            </div>
          </div>
          <div className={classNames(css.line, css.lineClear)} />
          <div className={css.footerLinks}>
            <Logo white />
            <div>©{YEAR} A New Horizon LLC</div>
          </div>
        </Section>
      </footer>
    </>
  )
}
