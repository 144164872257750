import { Section } from "../components/Section"
import classNames from "classnames"
import css from "./Pages.module.scss"
import MissionPhoto from "../assets/woman-in-blue-sweater-on-a-wheelchair.jpg"
import { Button } from "../components/Button"
import { Link } from "react-router-dom"

export const About = () => {
  return (
    <>
      <Section noMarginTop noMarginBottom center>
        <h1 className={classNames(css.h1, css.center)}>Our Mission</h1>
      </Section>
      <Section blocks={2} noMarginTop>
        <div className={classNames(css.colJustifyCenter, css.aboutTextWrapper)}>
          <div className={css.h3}>We Listen</div>
          <p>
            Our Mission has always been to take our client's best interest to
            heart. We cater to the goals of our clients, their parents and
            guardians. We work hand in hand with them as we strive for the
            highest levels of growth, and overall personal success for each of
            them.
          </p>
          <div className={css.h3}>We Act</div>
          <p>
            We make our client's and their guardian's concerns our number one
            priority. We assess our client's needs, and we match them to the
            needs of the client. The client and their guardian make the final
            decision on who best fits their needs. As we value their opinions,
            we never guesss at what will be best for them.
          </p>
          <div className={css.h3}>We Care</div>
          <p>
            No one is ever a number, a body to fill an available slot, or a part
            of a general group. All of our clients are different, they're unique
            and we make sure that we treat them as an individual who requires
            their needs met by compassion and oversight.
          </p>
          <p>
            We understand that finding the right care can be difficult, we would
            love to get in touch with you and answer any questions or concerns
            you may have. Also please see our{" "}
            <Link to="/contact#FAQ" className={css.plainLink}>
              Frequently Asked Questions
            </Link>{" "}
            that may help.
          </p>
          <Button link="/contact" square>
            Get The Care You Need
          </Button>
        </div>
        <img
          className={css.photo}
          alt="woman in blue sweater on a wheelchair"
          src={MissionPhoto}
        />
      </Section>
      {/* <Section noMarginTop noMarginBottom>
        <div id="team" />
        <h2 className={css.h2}>Meet The Team</h2>
        <hr className={css.hr} />
      </Section>
      <Section noMarginTop noMarginBottom blocks={"fourTwo"}>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
      </Section>
      <Section noMarginTop blocks={"fourTwo"}>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
        <div className={css.teamMember}>
          <div className={css.teamMemberPhoto}>No Photo</div>
          <div className={css.teamMemberName}>Team Member Name</div>
          <div className={css.teamMemberTitle}>Team Member Title</div>
        </div>
      </Section> */}
    </>
  )
}
