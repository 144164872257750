import { Button } from "../components/Button"
import { Section } from "../components/Section"
import { NotFound } from "./NotFound"
import classNames from "classnames"
import css from "./Pages.module.scss"
import { useParams } from "react-router-dom"

import ABAPhoto from "../assets/care-provider-helping-girl.jpg"
import PBSPhoto from "../assets/care-provider-engaging-with-a-client.jpg"
import DDPhoto from "../assets/smiley-girl-painting.jpg"
import EPDPhoto from "../assets/elderly-person-being-taken-care-by-female-caretaker.jpg"
import PHCPhoto from "../assets/in-home-care.jpg"

export const SERVICE_MAP = {
  "applied-behavior-analysis": {
    tag: {
      title: "Applied Behavior Analysis (ABA)",
      detail: (
        <p>
          ABA is a scientifically proven approach that uses principles of
          learning and behavior to help individuals develop new skills and
          reduce challenging behaviors
        </p>
      ),
    },
    main: {
      title: "ABA Therapy Solutions",
      detail: (
        <p>
          At A New Horizon, we offer individualized ABA Therapy sessions
          tailored to each person's unique needs, fostering growth and
          development in an inclusive atmosphere. Our dedication lies in
          cultivating a nurturing space where every individual can thrive,
          finding joy and fulfillment in their therapeutic journey. Recognizing
          the significance of collaboration, we prioritize building strong
          relationships with clients, caregivers, and staff, laying the
          groundwork for effective ABA Therapy outcomes. With compassionate
          curiosity at the core of our approach, we honor every perspective and
          voice, ensuring that each person's experiences are valued and
          validated.
        </p>
      ),
    },
    services: {
      title: "ABA Services Offered",
      image: {
        alt: "care provider helping girl",
        src: ABAPhoto,
      },
      detail: (
        <p>
          A New Horizon ensures that ABA therapy is founded on evidence-based
          practices, incorporating the latest techniques supported by research,
          with a primary focus on building trust and respect between client and
          therapist. Tailored to meet individual needs, our services can be
          delivered at home or in a center-based setting.
        </p>
      ),
      servicesLine: "Types of ABA care offered",
      services: ["Center Based ABA", "At Home ABA"],
    },
  },
  "positive-behavior-support": {
    tag: {
      title: "Positive Behavior Support (PBS)",
      detail: (
        <p>
          PBS is an evidence-based approach aimed at understanding and
          addressing challenging behaviors by promoting positive alternatives
          and creating supportive environments conducive to growth and
          development.
        </p>
      ),
    },
    main: {
      title: "PBS Solutions",
      detail: (
        <p>
          At A New Horizon, we specialize in providing personalized Positive
          Behavior Support services tailored to meet the unique needs of each
          individual. Our commitment lies in fostering an environment where
          positive change flourishes, empowering individuals to lead fulfilling
          lives enriched with meaningful connections and experiences.
          Emphasizing collaboration as a cornerstone, we prioritize establishing
          strong partnerships with clients, families, and our dedicated team
          members to ensure comprehensive support and successful outcomes.
          Grounded in empathy and understanding, our PBS approach values and
          validates the diverse perspectives and voices of all involved,
          fostering a sense of belonging and empowerment.
        </p>
      ),
    },
    services: {
      title: "PBS Services Offered",
      image: {
        alt: "care provider engaging with a client ",
        src: PBSPhoto,
      },
      detail: (
        <p>
          A New Horizon is dedicated to delivering PBS services rooted in
          evidence-based practices, incorporating the latest research-backed
          strategies with a focus on building trusting relationships and mutual
          respect between individuals and support providers. Our services are
          flexible and can be provided in-home or at a center-based location to
          best accommodate individual preferences and needs.
        </p>
      ),
      servicesLine: "Types of PBS care offered",
      services: ["Center Based PBS", "At Home PBS"],
    },
  },
  "developmental-disability": {
    tag: {
      title: "Developmental Disability Care (DD)",
      detail: (
        <p>
          encompasses a range of support services and interventions tailored to
          individuals with developmental disabilities, aimed at promoting their
          well-being, independence, and integration into society
        </p>
      ),
    },
    main: {
      title: "DD Support",
      detail: (
        <>
          <p>
            At A New Horizon, we specialize in delivering comprehensive
            caregiving solutions tailored to children and adults with
            developmental disabilities, offering a diverse array of services and
            resources throughout Arizona to holistically support our clients.
            With a dedicated team committed to understanding the unique needs of
            each individual, we passionately strive to empower our clients to
            thrive and achieve their fullest potential. As stated by the CDC,
            developmental disabilities encompass a range of impairments
            affecting physical, learning, language, or behavioral areas,
            impacting approximately one in six children in the U.S. Our mission
            is to provide personalized care that fosters growth, independence,
            and the pursuit of a fulfilling life for every individual we serve.
          </p>
        </>
      ),
    },
    services: {
      title: "DD Services Offered",
      image: {
        alt: "smiley girl painting",
        src: DDPhoto,
      },
      detail: (
        <p>
          At A New Horizon, we offer a comprehensive range of specialized care
          services tailored to individuals with developmental disabilities. Our
          Adult Developmental Home (ADH) program provides a nurturing and
          supportive living environment for adults, promoting independence and
          community integration. Through our Attendant Care services, we offer
          personalized assistance with daily activities, ensuring comfort and
          well-being. Our Habilitation programs focus on skill development and
          enhancing independence, while Individualized Habilitation Support
          offers personalized guidance and encouragement. With our Individually
          Designed Living Arrangement (IDLA) services, we create living
          environments tailored to individual needs and preferences.
          Additionally, our Respite services provide temporary relief and
          support to caregivers, ensuring they have the opportunity for rest and
          rejuvenation. At A New Horizon, we are committed to empowering
          individuals with developmental disabilities to live fulfilling and
          enriched lives.
        </p>
      ),
      servicesLine: "Types of DD care offered",
      services: [
        "Adult Developmental Home (ADH)",
        "Attendant Care",
        "Habilitation",
        "Individualized Habilitation Support",
        "Individually Designed Living Arrangement (IDLA)",
        "Respite",
      ],
    },
  },
  "elderly-and-physically-disabled": {
    tag: {
      title: "Elderly and Physically Disabled Care (EPD)",
      detail: (
        <p>
          EPD encompasses specialized support and assistance tailored to the
          unique needs of aging individuals and those with physical disabilities
        </p>
      ),
    },
    main: {
      title: "EPD Support",
      detail: (
        <p>
          As individuals age, their desire for independence often leads them to
          prefer staying in their own homes rather than opting for institutional
          care. Our non-medical, in-home Elder Care offers a secure and
          efficient solution, allowing aging individuals to receive personalized
          care within familiar surroundings. With a team of experienced,
          compassionate, and well-trained staff, we are dedicated to assisting
          you or your loved ones in making informed decisions regarding in-home
          care. We provide a comprehensive range of high-quality services
          tailored to support aging or physically disabled individuals in
          maintaining their independence and comfort within their own homes.
        </p>
      ),
    },
    services: {
      title: "EPD Services Offered",
      image: {
        alt: "elderly person being taken care by female caretaker",
        src: EPDPhoto,
      },
      detail: (
        <p>
          At A New Horizon, we understand the importance of providing
          comprehensive care options tailored to the unique needs of elderly and
          physically disabled individuals. Our Attendant Care services offer
          personalized assistance with daily activities, ensuring comfort and
          safety while promoting independence. With Companion Care, we provide
          social and emotional support, fostering meaningful connections and
          companionship for those in need. Additionally, our Respite Services
          for the elderly offer temporary relief and support to caregivers,
          allowing them to recharge while ensuring their loved ones receive
          quality care. At A New Horizon, we are dedicated to enhancing the
          well-being and quality of life for elderly and physically disabled
          individuals through compassionate and professional care services.
        </p>
      ),
      servicesLine: "Types of EPD care offered",
      services: [
        "Attendant Care",
        "Companion Care",
        "Respite Services for Elderly",
      ],
    },
  },
  "private-home-care": {
    tag: {
      title: "Private Home Care",
      detail: (
        <p>
          Provides personalized and dedicated support services tailored to
          individuals in the comfort and familiarity of their own homes
        </p>
      ),
    },
    main: {
      title: "Custom Care",
      detail: (
        <p>
          Private Home Care offers personalized and dedicated support services
          designed to meet the individual needs of clients within the comfort
          and familiarity of their own homes. Our highly trained and
          compassionate caregivers provide a wide range of assistance, including
          help with daily activities, medication management, meal preparation,
          and companionship. With Private Home Care, clients receive customized
          care plans tailored to their unique preferences and requirements,
          ensuring they maintain independence while receiving the support they
          need to live comfortably and safely in their own residences. Our goal
          is to enhance the quality of life for our clients by providing
          attentive and reliable care that promotes well-being and peace of mind
          for both them and their families.
        </p>
      ),
    },
    services: {
      title: "Our Services",
      image: {
        alt: "in home care taker preparing meal for elderly",
        src: PHCPhoto,
      },
      detail: (
        <>
          <p>
            At A New Horizon, our Private Home Care services encompass a range
            of specialized care options tailored to meet the diverse needs of
            our clients. With Companion Care, our caregivers offer social
            interaction, emotional support, and companionship, fostering
            meaningful connections and alleviating loneliness. Our Homemaker
            services include assistance with household tasks such as cleaning,
            laundry, and meal preparation, ensuring a comfortable and organized
            living environment for our clients. Personal Care services focus on
            individual hygiene, grooming, and mobility assistance, promoting
            personal well-being and dignity. Additionally, our Independent
            Living Services support clients in managing daily activities,
            coordinating appointments, and accessing community resources,
            empowering them to maintain independence and autonomy within their
            own homes. With our comprehensive approach to Private Home Care, we
            strive to enhance the quality of life and promote overall well-being
            for each individual we serve.
          </p>
        </>
      ),
      servicesLine: "Forms of Care",
      services: [
        "Companion Care",
        "Homemaker",
        "Personal Care",
        "Independent Living Services",
      ],
    },
  },
}

export const Services = (props) => {
  const { careType } = props
  if (!SERVICE_MAP[careType]) {
    return <NotFound />
  }

  const { tag, main, services } = SERVICE_MAP[careType]
  return (
    <>
      <Section backgroundColor="gray" noMarginTop noMarginBottom>
        <div className={css.serviceTag}>
          <h1 className={classNames(css.h2, css.serviceTagTitle)}>
            {tag.title}
          </h1>
          <div className={css.servicesTextWrapper}>{tag.detail}</div>
        </div>
      </Section>
      <Section backgroundColor="gray" noMarginTop noMarginBottom>
        <h2 className={css.h2}>{main.title}</h2>
        <div className={css.servicesTextWrapper}>{main.detail}</div>
      </Section>
      <Section blocks={2} backgroundColor="gray" noMarginTop>
        <img
          className={css.photo}
          alt={services.image.alt}
          src={services.image.src}
        />
        <div>
          <h3 className={css.h2}>{services.title}</h3>
          <div className={css.servicesTextWrapper}>{services.detail}</div>
          <p className={css.servicesTextWrapper}>
            <b>{services.servicesLine}</b>
          </p>
          <ul className={css.servicesTextWrapper}>
            {services.services.map((service, index) => (
              <li key={`serviceLi-${index}`}>{service}</li>
            ))}
          </ul>
          <br />
          <Button link="/contact" outerPadding>
            Contact Us For More Info
          </Button>
        </div>
      </Section>
    </>
  )
}
