import { Section } from "../components/Section"
import classNames from "classnames"
import css from "./Pages.module.scss"

export const NotFound = () => {
  return (
    <Section backgroundColor="gray" fullHeight noMarginTop>
      <div className={css.serviceTag}>
        <h1 className={classNames(css.h1, css.serviceTagTitle)}>
          404 Not Found
        </h1>
        <p>Sorry, we couldn't find the page you are looking for</p>
      </div>
    </Section>
  )
}
