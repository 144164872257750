import { useState } from "react"
import { Button } from "./Button"
import css from "./ContactForm.module.scss"
import pageCss from "../pages/Pages.module.scss"
import { contactFormSubmit } from "../requests/contactFormSubmit.js"
import classNames from "classnames"

export const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (event) => {
    setSubmitting(true)
    let response
    try {
      response = await contactFormSubmit(event)
    } catch {
      response = { status: 500 }
    }
    window.alert(
      response.status === 200
        ? "We received your form submission!"
        : "There was an error with submitting your form. Please contact us at anewhorizon.agency@gmail.com"
    )
    setSubmitting(false)
    event.target.reset()
  }

  return (
    <form
      className={classNames(css.form, { [css.submitting]: submitting })}
      onSubmit={onSubmit}
    >
      <div className={pageCss.h3}>Contact Form</div>
      <label htmlFor="name">
        Name <span className={css.required}>*required</span>
      </label>
      <input type="text" id="name" name="name" disabled={submitting} required />
      <label htmlFor="email">
        Email <span className={css.required}>*required</span>
      </label>
      <input
        type="email"
        id="email"
        name="email"
        pattern={/.+\@.+\..+/}
        disabled={submitting}
        required
      />
      <label htmlFor="phone">Phone</label>
      <input type="tel" id="phone" name="phone" disabled={submitting} />
      <label htmlFor="message">
        Message <span className={css.required}>*required</span>
      </label>
      <textarea
        type="text"
        id="message"
        name="message"
        rows={7}
        placeholder="Let us know how we can help!"
        disabled={submitting}
        required
      />
      <Button type="submit" disabled={submitting}>
        {submitting ? <i>Submitting</i> : "Submit"}
      </Button>
    </form>
  )
}
