import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button } from "../components/Button"
import { Section } from "../components/Section"
import css from "./Pages.module.scss"
import CarePhoto from "../assets/woman-helping-boy-with-down-syndrome-draw.jpg"
import { Carousel } from "../components/Carousel"
import { SERVICE_MAP } from "./Services"
import classNames from "classnames"
import { ReactComponent as BuildingIcon } from "../assets/building-solid.svg"
import { ReactComponent as HandsIcon } from "../assets/hand-holding-heart-solid.svg"
import { ReactComponent as FamilyIcon } from "../assets/children-solid.svg"

const testimonials = [
  {
    text: `Finding quality care for our loved one was a daunting task until
    we came across A New Horizon. From the very first consultation to
    ongoing support, their team has been exceptional. The caregivers
    are compassionate, skilled, and truly dedicated to improving the
    lives of those they serve. We couldn't be happier with the care
    our family member receives. Thank you, for providing such
    invaluable support.`,
    name: "- Testimonial 1",
  },
  {
    text: `I cannot express enough gratitude to the team at A New Horizon. As
    a caregiver, I often felt overwhelmed and exhausted. However, with
    their respite care services, I finally found the relief I needed.
    Knowing that my loved one is in capable hands allows me to take
    much-needed breaks, recharge, and attend to my own well-being. I
    highly recommend A New Horizon to anyone in need of respite care.
    They have truly been a lifeline for our family.`,
    name: "- Testimonial 2",
  },
  {
    text: `After searching for a reputable habilitation care provider, we
    stumbled upon A New Horizon, and it was a game-changer. Their team
    took the time to understand our loved one's unique needs and
    developed a personalized care plan that has made a world of
    difference. The progress we've seen since starting with A New
    Horizon is remarkable. Their dedication, expertise, and genuine
    care shine through in everything they do. We are beyond grateful
    for their support and highly recommend their services.`,
    name: "- Testimonial 3",
  },
]

export const Home = () => {
  const [testimonialIndex, setTestimonialIndex] = useState(0)
  // rename class when testimonials are in
  const [_testimonialClass, setTestimonialClass] = useState()

  const changeTestimonial = () => {
    setTestimonialClass(css.fadeOut)
    setTimeout(() => {
      setTestimonialIndex(
        testimonialIndex + 1 === testimonials.length ? 0 : testimonialIndex + 1
      )
      setTestimonialClass()
    }, 600)
  }

  useEffect(() => {
    const interval = setInterval(changeTestimonial, 15000)
    return () => clearInterval(interval)
  })

  return (
    <>
      <Section noMarginTop noMarginBottom hero backgroundColor="gray">
        <div className={css.hero}>
          <div className={css.heroText}>
            <div className={classNames(css.h1, css.heroTitle)}>
              New Horizons Are Ahead
            </div>
            <div className={classNames(css.h3, css.heroDetail)}>
              Compassionate Care for Every Generation
            </div>
          </div>
        </div>
      </Section>
      <Section blocks={2}>
        <img
          className={css.photo}
          alt="woman in blue sweater on a wheelchair"
          src={CarePhoto}
        />
        <div className={css.homeTextWrapper}>
          <h1 className={css.h2}>Your Trusted Partner in Care</h1>
          <p>
            At A New Horizon, we recognize the paramount importance of trust,
            especially in the realms of respite and habilitation care. As your
            dependable partner, we offer respite services designed to provide
            you with much-needed relief while ensuring your loved one receives
            the highest quality of care. Our habilitation programs are
            meticulously crafted to empower individuals with special needs,
            fostering independence and personal growth every step of the way.
            With our unwavering commitment to your family's well-being, you can
            find solace in knowing that we stand by your side, offering
            compassionate support and guidance in navigating the journey of care
            and comfort.
          </p>
          <p>
            Beyond just providing respite and habilitation care, we are deeply
            invested in building lasting relationships with both our clients and
            their families. Our approach is centered on open communication,
            transparency, and a genuine commitment to understanding your unique
            needs and preferences. We believe in creating a supportive
            environment where individuals feel valued, respected, and empowered
            to live life to the fullest. Whether it's arranging meaningful
            activities, offering emotional support, or simply lending a
            listening ear, our team is dedicated to fostering a sense of
            belonging and community. At A New Horizon, we don't just offer care
            - we become an integral part of your journey, walking alongside you
            every step of the way.
          </p>
          <br />
          <Button link="/about" outerPadding>
            About A New Horizon
          </Button>
        </div>
      </Section>
      <Section backgroundColor="gray" blocks={2}>
        <div className={css.colCenter}>
          <div>
            <h2 className={css.h3}>Our services</h2>
            <div className={css.h2}>Find the perfect care</div>
          </div>
          <Button link="/contact" square naked>
            <h4 className={css.h4}>Contact Us</h4>
          </Button>
        </div>
        <div className={css.colCenter}>
          <Button link="/services/applied-behavior-analysis" float before="ABA">
            <div className={css.h4}>Applied Behavior Analysis</div>
            <div className={css.learnMore}>Learn more</div>
          </Button>
          <Button link="/services/positive-behavior-support" float before="ABA">
            <div className={css.h4}>Positive Behavior Support</div>
            <div className={css.learnMore}>Learn more</div>
          </Button>
          <Button link="/services/developmental-disability" float>
            <div className={css.h4}>Developmentally Disabled</div>
            <div className={css.learnMore}>Learn more</div>
          </Button>
          <Button link="/services/elderly-and-physically-disabled" float>
            <div className={css.h4}>Elderly and Physically Disabled</div>
            <div className={css.learnMore}>Learn more</div>
          </Button>
          <Button link="/services/private-home-care" float>
            <div className={css.h4}>Private Home Care</div>
            <div className={css.learnMore}>Learn more</div>
          </Button>
        </div>
      </Section>
      <Section backgroundColor="primary" noMarginTop noMarginBottom>
        <Carousel
          items={Object.values(SERVICE_MAP).map((i) => i.services.image)}
        />
      </Section>
      {/* <Section
        backgroundColor="primary"
        noMarginTop
        noMarginBottom
        blocks="third"
      >
        <div className={css.testimonialsWrapper}>
          <h3 className={classNames(css.h3, css.quote)}>Testimonials</h3>
          <div className={css.h2}>Hear About The Impact We've Had</div>
        </div>
        <div className={css.testimonials}>
          <div className={classNames(css.testimonial, testimonialClass)}>
            <p className={css.testimonialQuote}>
              {testimonials[testimonialIndex].text}
            </p>
            <p role="heading" className={css.testimonialName}>
              {testimonials[testimonialIndex].name}
            </p>
          </div>
          <div className={classNames(css.testimonial, css.testimonialLarge)}>
            <p className={css.testimonialQuote}>{testimonials[2].text}</p>
            <p className={css.testimonialName}>{testimonials[2].name}</p>
          </div>
        </div>
      </Section> */}
      <Section backgroundColor="gray" noMarginBottom>
        <div className={css.statsText}>
          <div className={css.h2}>From humble beginnings in 2009</div>
          <div className={css.h3}>
            We've been able to carry out{" "}
            <Link className={css.plainLink} to="/about">
              our mission
            </Link>{" "}
            all throughout Arizona
          </div>
          {/* uncomment once tem is in */}
          {/* <h4 className={css.h4}>
            <Link className={css.plainLink} to="/about#team">
              Meet our team
            </Link>{" "}
            of incredible providers
          </h4> */}
        </div>
      </Section>
      <Section backgroundColor="gray" blocks={3}>
        <div className={css.colAlignCenter}>
          <div className={css.statIcon}>
            <FamilyIcon role="none" aria-roledescription="icon of a family" />
          </div>
          <div className={css.h1}>40+</div>
          <div className={css.h4}>Incredible Providers</div>
        </div>
        <div className={css.colAlignCenter}>
          <div className={css.statIcon}>
            <BuildingIcon
              role="none"
              aria-roledescription="icon of a building"
            />
          </div>
          <div className={css.h1}>11</div>
          <div className={css.h4}>Locations</div>
        </div>
        <div className={css.colAlignCenter}>
          <div className={css.statIcon}>
            <HandsIcon
              role="none"
              aria-roledescription="icon of caring hands"
            />
          </div>
          <div className={css.h1}>1</div>
          <div className={css.h4}>Mission</div>
        </div>
      </Section>
      <Section backgroundColor="gray" noMarginTop>
        <div className={css.statsText}>
          <div className={css.h4}>
            Interested in becoming a care provider? <br />
            Check out our{" "}
            <Link className={css.plainLink} to="/careers">
              careers
            </Link>{" "}
            page. We would love to work with you!
          </div>
        </div>
      </Section>
    </>
  )
}
