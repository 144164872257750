import { useState } from "react"
import css from "./Question.module.scss"
import classNames from "classnames"

export const Question = (props) => {
  const { question, children } = props
  const [open, setOpen] = useState(false)

  return (
    <div
      className={classNames(css.main, { [css.mainOpen]: open })}
      onClick={() => setOpen(!open)}
    >
      <div className={css.questionWrapper}>
        <div className={css.question}>{question}</div>
        <div
          className={classNames(css.plusMinus, {
            [css.plusMinusOpen]: open,
          })}
        >
          +
        </div>
      </div>
      <div className={classNames(css.answerWrapper, { [css.open]: open })}>
        <div className={css.answer}>{children}</div>
      </div>
    </div>
  )
}
