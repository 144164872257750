import { Link } from "react-router-dom"
import classNames from "classnames"
import css from "./Logo.module.scss"
import { ReactComponent as LogoSvg } from "../assets/logo.svg"

export const Logo = (props) => {
  const { onClick, white } = props
  return (
    <Link
      to="/home"
      className={classNames(css.logo, { [css.white]: white })}
      {...(onClick && { onClick })}
    >
      <LogoSvg
        role="none"
        aria-roledescription="logo"
        alt="logo-of-a-bird-on-a-branch"
        className={classNames(css.svg, { [css.whiteSvg]: white })}
      />
      <span>A New Horizon</span>
    </Link>
  )
}
